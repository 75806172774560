import { render, staticRenderFns } from "./PageUnderConstruction.vue?vue&type=template&id=5e2bf7dc&scoped=true&"
import script from "./PageUnderConstruction.vue?vue&type=script&lang=js&"
export * from "./PageUnderConstruction.vue?vue&type=script&lang=js&"
import style0 from "./PageUnderConstruction.vue?vue&type=style&index=0&id=5e2bf7dc&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e2bf7dc",
  null
  
)

export default component.exports